import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FooterWhite from "components/Footers/FooterWhite.js";

import Firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
  authDomain: "onroad3-e593b.firebaseapp.com",
  databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
  projectId: "onroad3-e593b",
  storageBucket: "onroad3-e593b.appspot.com",
  messagingSenderId: "587064425563",
  appId: "1:587064425563:web:99aac8f9a9a8b7cae9137e",
  measurementId: "G-5VFLZ6SK9T"
}
Firebase.initializeApp(firebaseConfig);


function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
 
  const [list, setList] = React.useState();
  const [people, setPeople] = React.useState();

  const [storeUiniqId, setStoreUiniqId] = React.useState();
  const icons = ['app', 'bullet-list-67', 'image', 'palette', 'scissors', 'vector', 'diamond', 'globe', 'key-25', 'planet', 'spaceship',  'support-17',' umbrella-13']

  
  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/serviceofferings/FRwt0F9CAxucS1Shwl1+vUnpQ==/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          if(data.services){
            let result = []
            const keys = Object.keys(data.services);
            for (let index = keys.length - 1; index > -1; index--) {
              if(data.services[keys[index]] && data.services[keys[index]].name && data.services[keys[index]].description){
                const element = Object.assign(data.services[keys[index]], {id: keys[index]})
                result.push(element)
              }
            }
            setList(result)
          }
          if(data.people){
            let result = []
            const keys = Object.keys(data.people);
            for (let index = keys.length - 1; index > -1; index--) {
              if(data.people[keys[index]] && data.people[keys[index]].name && data.people[keys[index]].phone){
                const element = Object.assign(data.people[keys[index]], {id: keys[index]})
                result.push(element)
              }
            }
            setPeople(result)
          }
          
        }
      });
    return () => Firebase.database().ref(`/serviceofferings/FRwt0F9CAxucS1Shwl1+vUnpQ==/`).off('value', onChildAdd);
  }, [])

  return (
    <>
      {/* <MultiDropdownNavbar /> */}
      <LandingPageHeader name={"Instant Laundry Pickup"} serviceList={list} phone={people} />
      <div className="wrapper">
        <div className="section text-center landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Services</h2>
                <h5>
                Let us save you a trip to a laundromat or just check something off your to do list! 
Convenient, free pick up and delivery Monday through Friday with next day delivery.
We also provide dry cleaning services and cleaning of comforters, sheets, tablecloths and other household items and even your patio cushions
                </h5>
                <br />
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              {list && list.map((item, index)=>{
                return(
                  <Col md={list.length > 4 ? "3" : ""}>
                <div className="info">
                  <div className="icon icon-danger">
                    <i className={`nc-icon nc-${icons[index%12]}`} />
                  </div>
                  <div className="description">
                    <h4 className="info-title">{item.name}</h4>
                    <p className="description">
                      {/* Spend your time generating new ideas. You don't have to
                      think of implementing. */}
                      {item.description}
                    </p>
                  </div>
                </div>
              </Col>
                )
              })}
            
            </Row>
            
          </Container>
        </div>
        <div className="section section-dark text-center landing-section">
          <Container>
            <h2 className="title">Our Team</h2>
            <Row>
              {people && people.map((p)=>{
                return(
                  <Col>
                <Card className="card-profile card-plain">
                  {/* <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/faces/clem-onojeghuo-3.jpg")
                            .default
                        }
                      />
                    </a>
                  </div> */}
                  <CardBody>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">{p.name}</CardTitle>
                        <h6 className="card-category text-muted">
                          Operations Manager
                        </h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Ping me for any questions. {p.phone}
                    </p>
                  </CardBody>
                </Card>
              </Col>
                )
              })}       
            </Row>
          </Container>
          <Container>
            <h2 className="title">Our Partners</h2>
            <Row  className="title" style={{justifyContent: "center"}}>
              <p>All 5 star laundry shops across the city, ask pickup person</p>     
            </Row>
          </Container>
        </div>

      </div>
    </>
  );
}

export default LandingPage;

import React from "react";

// reactstrap components
import { Button, Container,Modal,   InputGroupAddon,
  InputGroupText, Input,
  InputGroup,  FormGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row, Col,
  Card,
  CardTitle,
  Table,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';
// core components
import Firebase from "firebase";
import moment from "moment";


const MapWrapper = ({destination}) => {

  const [markerRef, setMarkerRef] = React.useState();
  const [remainingTime, setRemainingTime] = React.useState(0);
  const [remainingDistance, setRemainingDistance] = React.useState(0);
  const [deliveryGuyLocationLiveTracking, setDeliveryGuyLocationLiveTracking] = React.useState(null);
  const deliveryGuyPhone = "+917760596408"
  const mapRef = React.useRef();
  const google = window.google;
  const directionsService = new google.maps.DirectionsService();

  const pinMarkerSVG =
  "M10 3.5C10 4.70948 9.14112 5.71836 8 5.94999V13.5C8 13.7761 7.77614 14 7.5 14C7.22386 14 7 13.7761 7 13.5V5.94999C5.85888 5.71836 5 4.70948 5 3.5C5 2.11929 6.11929 1 7.5 1C8.88071 1 10 2.11929 10 3.5Z";
  const squareMarkerSVG =
  "M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z";
  

  React.useEffect(() => {
    if (deliveryGuyPhone) {
      const onChildAdd = Firebase.database()
        .ref(`/userlivelocationtracking/${deliveryGuyPhone}`)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data && data.latitude && data.longitude) {
            setDeliveryGuyLocationLiveTracking({
              lat: parseFloat(data.latitude),
              lng: parseFloat(data.longitude)
            })
            console.log("===delivery guy latlng====", data)
            if(destination) {
              directionsService.route(
                {
                  destination: {
                    lat: destination.lat,
                    lng: destination.lng,
                  },
                  origin: {
                    lat: data.latitude,
                    lng: data.longitude,
                  },
                  travelMode: "DRIVING",
                },
                (result, status) => {
                  if (status == "OK") {
                    setRemainingDistance(result.routes[0].legs[0].distance.text)
                    setRemainingTime(result.routes[0].legs[0].duration.text)
                  }
                })
            }
          }
        });
      // Stop listening for updates when no longer required
      return () => Firebase.database().ref(`/userlivelocationtracking/${deliveryGuyPhone}`).off('value', onChildAdd);
    }
  }, [destination]);

  React.useEffect(() => {
    if (deliveryGuyLocationLiveTracking && markerRef) {
      setTimeout(() => {
        markerRef.setPosition({
          lat: deliveryGuyLocationLiveTracking.lat,
          lng: deliveryGuyLocationLiveTracking.lng
        })
        // mapRef.panTo({lat: deliveryGuyLocationLiveTracking.lat,lng: deliveryGuyLocationLiveTracking.lng});
        // mapRef.setZoom(11);
        // animatingCamera(position.coords.latitude, position.coords.longitude, position.coords.heading);
        // getPointOnPath([position.coords.latitude, position.coords.longitude])

        // TODO 
        // mapRef.setHeading(45);
      }, 1000)
    }
  }, [deliveryGuyLocationLiveTracking])


  React.useEffect(() => {
    if(!deliveryGuyLocationLiveTracking){
      return ;
    }
    let map = mapRef.current;
    const myLatlng = new google.maps.LatLng(destination.lat, destination.lng);
    const mapOptions = {
      zoom: 13,
      center: myLatlng,
      scrollwheel: false,
      mapTypeControl: false,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#d6e2e6",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#cfd4d5",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#7492a8",
            },
          ],
        },
        {
          featureType: "administrative.neighborhood",
          elementType: "labels.text.fill",
          stylers: [
            {
              lightness: 25,
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#dde2e3",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#cfd4d5",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#dde2e3",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#7492a8",
            },
          ],
        },
        {
          featureType: "landscape.natural.terrain",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#dde2e3",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            {
              saturation: -100,
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#588ca4",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#a9de83",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#bae6a1",
            },
          ],
        },
        {
          featureType: "poi.sports_complex",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c6e8b3",
            },
          ],
        },
        {
          featureType: "poi.sports_complex",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#bae6a1",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              saturation: -45,
            },
            {
              lightness: 10,
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#41626b",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c1d1d6",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#a6b5bb",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#9fb6bd",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [
            {
              saturation: -70,
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#b4cbd4",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#588ca4",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#008cb5",
            },
          ],
        },
        {
          featureType: "transit.station.airport",
          elementType: "geometry.fill",
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: -5,
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#a6cbe3",
            },
          ],
        },
      ],
    }
    map = new google.maps.Map(map, mapOptions);
    const pinMarker = new google.maps.Marker({
      position: myLatlng,
      icon: {
        path: pinMarkerSVG,
        fillColor: "#000000",
        fillOpacity: 1,
        strokeColor: "#000000",
        scale: 2,
        anchor: {
          x: 5,
          y: 13,
        },
      },
      map: map,
    });

    const myLatlng2 = new google.maps.LatLng(deliveryGuyLocationLiveTracking.lat, deliveryGuyLocationLiveTracking.lng);
    const squareMarker = new google.maps.Marker({
      position: myLatlng2,
      icon: {
        path: squareMarkerSVG,
        fillColor: "#000000",
        fillOpacity: 1,
        strokeColor: "#000000",
      },
      map: map,
    });

    const liveTrackMarker = new google.maps.Marker({
      position: myLatlng2,
      icon: {
        path: squareMarkerSVG,
        fillColor: "#e91e63",
        fillOpacity: 1,
        strokeColor: "#e91e63",
      },
      map: map,
    });

    setMarkerRef(liveTrackMarker);
    
    function autoRefresh(map, pathCoords) {
      var i, route;

      route = new google.maps.Polyline({
        path: [],
        geodesic: true,
        strokeColor: "#000000",
        strokeWeight: 5,
        editable: false,
        map: map,
      });

      for (i = 0; i < pathCoords.length; i++) {
        setTimeout(
          function (coords) {
            route.getPath().push(coords);
          },
          10 * i,
          pathCoords[i]
        );
      }
    }

    const directionsDisplay = new google.maps.DirectionsRenderer({
      map: map,
      suppressMarkers: true,
      // polylineOptions: {
      //   strokeWeight: 5,
      //   strokeColor: "#808080",
      // },
      preserveViewport: true,
    });

    let bounds = new google.maps.LatLngBounds();

    directionsService.route(
      {
        destination: myLatlng,
        origin: myLatlng2,
        travelMode: "DRIVING",
      },
      (result, status) => {
        if (status == "OK") {
          // setLiveTrackMarkerRef(new google.maps.Marker({
          //   map: map
          // }));
          bounds.union(result.routes[0].bounds);
          map.fitBounds(bounds);

          var display = new google.maps.DirectionsRenderer({preserveViewport: true});

          display.setMap(map);
          directionsDisplay.setDirections(result);

          autoRefresh(map, result.routes[0].overview_path);
          setRemainingDistance(result.routes[0].legs[0].distance.text)
          setRemainingTime(result.routes[0].legs[0].duration.text)
        }
      }
    );
  
  }, [destination && deliveryGuyLocationLiveTracking])

      return (
      <>
        <div
          style={{
            height: `calc(100vh - 60px)`,
            borderRadius: "6px",
            overflow: "hidden",
          }}
          ref={mapRef}
        >
        </div>
        <div style={{
                position: "absolute",
                top: "40px",  /* adjust value accordingly */
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontWeight: "bold"
            }}>
            <Card>
              <CardTitle style={{padding: "5px", paddingRight: "10px", paddingLeft: "10px"}}>
                {remainingDistance} &nbsp; | &nbsp; {remainingTime}
              </CardTitle>
            </Card>
        </div>
        <div style={{
                position: "absolute",
                top: "83vh",  /* adjust value accordingly */
                left: "50%",
                transform: "translate(-50%, -50%)"
            }}>
            <Button
              color="danger"
              onClick={()=>{
                window.open(`tel:8622878740`, '_self');
              }}
            >
              Call
            </Button>
        </div>
      </>
    );
};


function LandingPageHeader(props) {
  let pageHeader = React.createRef();
  const [classic, setClassic] = React.useState(false);
  const [selectedService, setSelectedService] = React.useState();
  const [address, setAddress] = React.useState();
  const [addressLatLng, setAddressLatLng] = React.useState();
  const [addressLocation, setAddressLocation] = React.useState();
  const [phone, setPhone] = React.useState();
  const [landMark, setLandmark] = React.useState();
  const [name, setName] = React.useState();
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [interestedServices, setInterestedServices] = React.useState();
  const [previousOrders, setPreviousOrders] = React.useState();
  const [previousOrdersModal, setPreviousOrdersModal] = React.useState();
  const [pricingModal, setPricingModal] = React.useState();
  const [products, setProducts] = React.useState([
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Pick-Up and Delivery Charges',
        price: 50,
        discount: 15,
        description: 'Pick-Up and Delivery Charges'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Up To 3.5 Kgs. (Regular Load)',
        price: 200,
        discount: 15,
        description: 'Up To 3.5 Kgs. (Regular Load)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Up To 4.5 Kgs. (Regular Load)',
        price: 250,
        discount: 15,
        description: 'Up To 4.5 Kgs. (Regular Load)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Up To 5.5 Kgs. (Regular Load)',
        price: 300,
        discount: 15,
        description: 'Up To 5.5 Kgs. (Regular Load)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Up To 6.5 Kgs. (Regular Load)',
        price: 350,
        discount: 15,
        description: 'Up To 6.5 Kgs. (Regular Load)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Up To 7.5 Kgs. (Regular Load)',
        price: 400,
        discount: 15,
        description: 'Up To 7.5 Kgs. (Regular Load)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Up To 3.5 Kgs. (Heavy Load)',
        price: 300,
        discount: 15,
        description: 'Up To 3.5 Kgs. (Heavy Load)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Up To 4.5 Kgs. (Heavy Load)',
        price: 300,
        discount: 15,
        description: 'Up To 4.5 Kgs. (Heavy Load)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Up To 5.5 Kgs. (Heavy Load)',
        price: 350,
        discount: 15,
        description: 'Up To 5.5 Kgs. (Heavy Load)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Up To 6.5 Kgs. (Heavy Load)',
        price: 400,
        discount: 15,
        description: 'Up To 6.5 Kgs. (Heavy Load)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Up To 7.5 Kgs. (Heavy Load)',
        price: 430,
        discount: 15,
        description: 'Up To 7.5 Kgs. (Heavy Load)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Fabric Conditioner (Add On)',
        price: 15,
        discount: 15,
        description: 'Fabric Conditioner (Add On)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Dettol Wash (Add On)',
        price: 15,
        discount: 15,
        description: 'Dettol Wash (Add On)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Formal Shoes,Trekking Shoes/ Boots',
        price: NaN,
        discount: 15,
        description: 'Formal Shoes,Trekking Shoes/ Boots'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Other Shoes (Suede, Sports, etc.)',
        price: 250,
        discount: 15,
        description: 'Other Shoes (Suede, Sports, etc.)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Bath Mat',
        price: 250,
        discount: 15,
        description: 'Bath Mat'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Bath Robe',
        price: 59,
        discount: 15,
        description: 'Bath Robe'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Bath Towel Light',
        price: 99,
        discount: 15,
        description: 'Bath Towel Light'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Bath Towel Heavy',
        price: 69,
        discount: 15,
        description: 'Bath Towel Heavy'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Bed Spread/ Bed Cover Single',
        price: 89,
        discount: 15,
        description: 'Bed Spread/ Bed Cover Single'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Bed Spread/ Bed Cover Double',
        price: 129,
        discount: 15,
        description: 'Bed Spread/ Bed Cover Double'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Bedsheet Single',
        price: 169,
        discount: 15,
        description: 'Bedsheet Single'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Bedsheet Double',
        price: 99,
        discount: 15,
        description: 'Bedsheet Double'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Blanket Single',
        price: 129,
        discount: 15,
        description: 'Blanket Single'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Blanket Double',
        price: 299,
        discount: 15,
        description: 'Blanket Double'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Blazer/ Coat',
        price: 399,
        discount: 15,
        description: 'Blazer/ Coat'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Blouse/ Ghagra Top',
        price: 229,
        discount: 15,
        description: 'Blouse/ Ghagra Top'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Blouse/ Ghagra Top - Silk',
        price: 79,
        discount: 15,
        description: 'Blouse/ Ghagra Top - Silk'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Blouse/ Ghagra Top - With Work',
        price: 99,
        discount: 15,
        description: 'Blouse/ Ghagra Top - With Work'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Cap',
        price: 149,
        discount: 15,
        description: 'Cap'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Curtain - Cotton (Per SFT)',
        price: 69,
        discount: 15,
        description: 'Curtain - Cotton (Per SFT)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Curtain - Nylon/ Poly (Per SFT)',
        price: 8,
        discount: 15,
        description: 'Curtain - Nylon/ Poly (Per SFT)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Curtain - Silk (Per SFT)',
        price: 10,
        discount: 15,
        description: 'Curtain - Silk (Per SFT)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Door Mat',
        price: 14,
        discount: 15,
        description: 'Door Mat'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Dupatta Silk',
        price: 79,
        discount: 15,
        description: 'Dupatta Silk'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Dupatta With Work',
        price: 99,
        discount: 15,
        description: 'Dupatta With Work'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Ghagra',
        price: 169,
        discount: 15,
        description: 'Ghagra'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Ghagra - Silk, Embroidered, With Work, etc.',
        price: 279,
        discount: 15,
        description: 'Ghagra - Silk, Embroidered, With Work, etc.'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Gloves - Leather (Pair)',
        price: 349,
        discount: 15,
        description: 'Gloves - Leather (Pair)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Gloves - Wool (Pair)',
        price: 129,
        discount: 15,
        description: 'Gloves - Wool (Pair)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Hand Towel',
        price: 89,
        discount: 15,
        description: 'Hand Towel'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Handkerchief',
        price: 49,
        discount: 15,
        description: 'Handkerchief'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Jacket',
        price: 49,
        discount: 15,
        description: 'Jacket'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Jacket - Leather',
        price: 189,
        discount: 15,
        description: 'Jacket - Leather'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Jeans/ Denims',
        price: 399,
        discount: 15,
        description: 'Jeans/ Denims'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Kids Blazer/ Coat/ Silk Kurta',
        price: 99,
        discount: 15,
        description: 'Kids Blazer/ Coat/ Silk Kurta'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Kids Shirt/ T-Shirt/ Top/ Kurta',
        price: 99,
        discount: 15,
        description: 'Kids Shirt/ T-Shirt/ Top/ Kurta'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Kids Skirt/ Frock',
        price: 69,
        discount: 15,
        description: 'Kids Skirt/ Frock'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Kids Trouser/Jeans/ Pant',
        price: 69,
        discount: 15,
        description: 'Kids Trouser/Jeans/ Pant'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Kurti/ Kameez',
        price: 69,
        discount: 15,
        description: 'Kurti/ Kameez'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Kurti/ Kameez - Silk',
        price: 89,
        discount: 15,
        description: 'Kurti/ Kameez - Silk'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Long Coat/ Long Jacket',
        price: 119,
        discount: 15,
        description: 'Long Coat/ Long Jacket'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Long Gown With Work',
        price: 299,
        discount: 15,
        description: 'Long Gown With Work'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Long Gown/ Maxi',
        price: 299,
        discount: 15,
        description: 'Long Gown/ Maxi'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: "Men's Dhoti",
        price: 219,
        discount: 15,
        description: "Men's Dhoti"
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: "Men's Dhoti Silk",
        price: 99,
        discount: 15,
        description: "Men's Dhoti Silk"
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: "Men's Kurta",
        price: 149,
        discount: 15,
        description: "Men's Kurta"
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: "Men's Kurta Silk",
        price: 89,
        discount: 15,
        description: "Men's Kurta Silk"
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: "Men's Safari Pant",
        price: 129,
        discount: 15,
        description: "Men's Safari Pant"
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: "Men's Safari Shirt",
        price: 149,
        discount: 15,
        description: "Men's Safari Shirt"
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: "Men's Sherwani Set",
        price: 119,
        discount: 15,
        description: "Men's Sherwani Set"
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: "Men's Waistcoat/ Bandi",
        price: 599,
        discount: 15,
        description: "Men's Waistcoat/ Bandi"
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: "Men's Waistcoat/ Bandi Silk",
        price: 89,
        discount: 15,
        description: "Men's Waistcoat/ Bandi Silk"
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Night Wear',
        price: 149,
        discount: 15,
        description: 'Night Wear'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'One Piece/ Dress/ Western Dress',
        price: 89,
        discount: 15,
        description: 'One Piece/ Dress/ Western Dress'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Petticoat',
        price: 249,
        discount: 15,
        description: 'Petticoat'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Pillow (Single)',
        price: 79,
        discount: 15,
        description: 'Pillow (Single)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Pillow Cover/ Cushion Cover (Single)',
        price: 149,
        discount: 15,
        description: 'Pillow Cover/ Cushion Cover (Single)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Pullover/ Sweater',
        price: 69,
        discount: 15,
        description: 'Pullover/ Sweater'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Pyjama',
        price: 189,
        discount: 15,
        description: 'Pyjama'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Quilt Single',
        price: 69,
        discount: 15,
        description: 'Quilt Single'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Quilt Double',
        price: 299,
        discount: 15,
        description: 'Quilt Double'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Salwaar - Embroidered/ Stone Work, etc.',
        price: 399,
        discount: 15,
        description: 'Salwaar - Embroidered/ Stone Work, etc.'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Salwaar/ Pyjama/ Palazzo/ Patiala',
        price: 169,
        discount: 15,
        description: 'Salwaar/ Pyjama/ Palazzo/ Patiala'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Salwaar/ Pyjama/ Palazzo/ Patiala - Silk',
        price: 69,
        discount: 15,
        description: 'Salwaar/ Pyjama/ Palazzo/ Patiala - Silk'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Saree - Cotton/ Chiffon',
        price: 169,
        discount: 15,
        description: 'Saree - Cotton/ Chiffon'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Saree - Silk',
        price: 199,
        discount: 15,
        description: 'Saree - Silk'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Saree - Embroidered/ Stone Work, etc.',
        price: 289,
        discount: 15,
        description: 'Saree - Embroidered/ Stone Work, etc.'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Shawl',
        price: 399,
        discount: 15,
        description: 'Shawl'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Shawl - Woolen',
        price: 99,
        discount: 15,
        description: 'Shawl - Woolen'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Shirt',
        price: 149,
        discount: 15,
        description: 'Shirt'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Shorts',
        price: 69,
        discount: 15,
        description: 'Shorts'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Silk Shirt',
        price: 69,
        discount: 15,
        description: 'Silk Shirt'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Skirt',
        price: 89,
        discount: 15,
        description: 'Skirt'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Socks (Pair)',
        price: 79,
        discount: 15,
        description: 'Socks (Pair)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Sofa Cover (Per Seat)',
        price: 89,
        discount: 15,
        description: 'Sofa Cover (Per Seat)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Soft Toy (Large)',
        price: 89,
        discount: 15,
        description: 'Soft Toy (Large)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Soft Toy (Small)',
        price: 249,
        discount: 15,
        description: 'Soft Toy (Small)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Suit (2 Pc)',
        price: 169,
        discount: 15,
        description: 'Suit (2 Pc)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Suit (3 Pc)',
        price: 289,
        discount: 15,
        description: 'Suit (3 Pc)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'T-Shirt/ Top',
        price: 359,
        discount: 15,
        description: 'T-Shirt/ Top'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Table Cloth Big',
        price: 69,
        discount: 15,
        description: 'Table Cloth Big'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Table Cloth Small',
        price: 99,
        discount: 15,
        description: 'Table Cloth Small'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Table Mat',
        price: 69,
        discount: 15,
        description: 'Table Mat'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Table Napkin (Single)',
        price: 59,
        discount: 15,
        description: 'Table Napkin (Single)'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Tie',
        price: 59,
        discount: 15,
        description: 'Tie'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Track Pant',
        price: 69,
        discount: 15,
        description: 'Track Pant'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: 'Trouser/ Pant',
        price: 69,
        discount: 15,
        description: 'Trouser/ Pant'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/onroadlaundry.jpeg?alt=media&token=da6f4948-5b1a-4880-83e8-f344b7e4826a",
        name: "Women's Dupatta",
        price: 89,
        discount: 15,
        description: "Women's Dupatta"
      }
    ]
    );

  
  const togglePrevisouOrderModal = () => {
    setPreviousOrdersModal(!previousOrdersModal);
  };

  const togglePricingModal = () => {
    setPricingModal(!pricingModal);
  };
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  React.useEffect(() => {
    const a = window.localStorage.getItem("instantlaundry")
    if(a){
      const t = JSON.parse(a)
      if(t.length > 0){
        setPreviousOrders(t)
      }
    }
  }, [])

  const getCoordinates = (address) => {
    geocodeByAddress(address.value.description)
      .then(results => getLatLng(results[0]))
      .then((data) => {
        // console.log('LAT: ', data.lat);
        // console.log('LNG: ', data.lng);
        setAddress(address.value.description)
        if (address.value && address.value.structured_formatting && address.value.structured_formatting.secondary_text) {
          setAddressLocation(address.value.structured_formatting.secondary_text)
        }
        setAddressLatLng(data)
      })
  };
  const finalizeSchedule = async () =>{
    if(!phone || (phone && phone.length !== 10) || !name || !address || !addressLatLng || !landMark) {
      setError(true);
      return;
    }
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss")
    const orderObject = {
      name: name,
      phone: phone,
      address: address,
      addresslatlng: addressLatLng,
      addresslocation: addressLocation,
      landmark: landMark,
      service: selectedService,
      time: currentTime,
      status: "requested"
    }
    const orderRef = await Firebase.database()
      .ref(`/instantlaundry/orders/`)
      .push(orderObject)
    const orderFBID = await orderRef.key;
    setSuccess(true)
    setError(false);
    let localOrderObject = {}
    localOrderObject[orderFBID] = `time:${currentTime}:service:${selectedService}`
    const t = await window.localStorage.getItem("instantlaundry")
    if(t){
      let a = JSON.parse(t)
      if(a && a.length > 0){
        setPreviousOrders([localOrderObject, ...a])
        window.localStorage.setItem('instantlaundry', JSON.stringify([localOrderObject, ...a]))
      }else{
        setPreviousOrders([localOrderObject])
        window.localStorage.setItem('instantlaundry', JSON.stringify([localOrderObject]))
      }
    }else{
      setPreviousOrders([localOrderObject])
      window.localStorage.setItem('instantlaundry', JSON.stringify([localOrderObject]))
    } 
    
  }

  const previousOrdersList = () => {
    return (
      <>
      <Button
        className="btn-link"
        id="tooltip304204142"
        type="button"
        onClick={() => {
          setPreviousOrdersModal(true)
        }}
        style={{
          color: "#000000"
        }}
      >
        View Previous Orders            
      </Button>
      </>
    )
  }

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/onroad-laundry.jpeg").default +
            ")",
        }}
      >

        <Modal
            isOpen={previousOrdersModal}
            toggle={togglePrevisouOrderModal}
            className="modal-register"
          >
            <div className="modal-header no-border-header text-center">
              <button
                className="close"
                type="button"
                onClick={togglePrevisouOrderModal}
              >
                <span>×</span>
              </button>
              <h6 className="text-muted"> Previous Orders</h6>
            </div>
            <div className="modal-body">
              {previousOrders && previousOrders.length > 0 && (<Table responsive>
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">Time</th>
                    <th className="text-center">Service</th>
                    <th className="text-center">OrderID</th>
                  </tr>
                </thead>
                <tbody>
                  {previousOrders.map(order => {
                    return (
                      <>
                        {Object.entries(order).map(([key,value], index) => {
                        return (
                              <tr>
                                <td className="text-center">{index}</td>
                                <td>{value.split("time:")[1].split(":service:")[0]}</td>
                                <td>{value.split(":service:")[1]}</td>
                                <td>{key}</td>
                              </tr>
                        )})
                        }
                      </>
                    )
                  })}
                  
                </tbody>
              </Table>)}
            </div>
            <div className="modal-footer">
              <Button color="primary" className="justify-content-center" onClick={() => setPreviousOrdersModal(false)}>Ok</Button>
            </div>
        </Modal>
        
        <Modal
            isOpen={pricingModal}
            toggle={togglePricingModal}
            className="modal-register"
          >
            <div className="modal-header no-border-header text-center">
              <button
                className="close"
                type="button"
                onClick={togglePricingModal}
              >
                <span>×</span>
              </button>
              <h6 className="text-muted"></h6>
            </div>
            <div className="modal-body" style={{padding: 0}}>
            <Table className="table-shopping" responsive>
            <tbody>
                {products && products.map((product, key) => {
                  return(
                    <tr key={key}>
                      <td style={{width: "120px", padding: "2px"}}>
                        <div className="img-container">
                          <img
                            alt="..."
                            src={product.image}
                          />
                        </div>
                      </td>
                      <td className="td-name" style={{fontSize: "1em", padding: "2px"}}>
                        <a href="#product" onClick={(e) => e.preventDefault()} style={{color: "#f96332", fontWeight: "600"}}>
                          {product?.name?.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() })}&nbsp;
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        {product.discount && product.discount > 60 ? (<span style=
                        {{color: "white",background: "#9c3f3f", fontSize: "8px", fontWeight: "200",
                          padding: "5px", marginTop: "10px", verticalAlign: "middle",
                          borderRadius: "10px"}}>Hot Deal</span>): null}
                        <br />
                        <small style={{fontSize: "1em"}}>{product.discount>0 ? (<span style={{textDecoration: "line-through"}}>{parseInt((100/(100 - product.discount)) * product.price)}</span>): ""}&nbsp;&nbsp;{product.price}</small>
                        <br />
                        <small style={{fontSize: "1em"}}>{product.discount || 0}% Discount </small>
                        {product.description && (<> <br /><small style={{fontSize: "12px", color: "black"}}>{product.description || ""} </small></>)}
                      </td>
                  </tr>
                  )
                })}
              </tbody>
            </Table>
            </div>
            {/* <div className="modal-footer">
              <Button color="primary" className="justify-content-center" onClick={() => setPricingModal(false)}>Ok</Button>
            </div> */}
        </Modal>


        <Modal
            isOpen={classic}
            toggle={() => setClassic(false)}
            className="modal-register"
          >
          { success ?
            (
              <div className="big-map" id="contactUsMap" style={{height: "600px"}}>
                <MapWrapper destination={addressLatLng}/>
              </div>
            ): 
            (
              <>
              <div className="modal-header no-border-header text-center">
              <button
                className="close"
                type="button"
                onClick={() => setClassic(false)}
              >
                <span>×</span>
              </button>
              <h6 className="text-muted">{success ? "Live Track" : "Order Instant Pickup"}</h6>
              {error && (<p style={{color: "red"}}><b>Please enter all fields </b></p>)}
            </div>
            <div className="modal-body">
              <FormGroup>
                <label>Your Address</label>
                <GooglePlacesAutocomplete
                  selectProps={{
                  onChange: (address) => getCoordinates(address),
                }}
                  placeholder="Search Address "
                  apiKey = "AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk"
                />
                {/* <Input defaultValue="" placeholder="Address" type="text" /> */}
              </FormGroup>
              <FormGroup> 
                <label>Landmark</label>
                <Input defaultValue="" placeholder="Apartment or PG or Flat Number" type="text" onBlur={(e)=>{
                  setLandmark(e.target.value)
                }}/>
              </FormGroup>
              <FormGroup>
                <label>Phone</label>
                <Input defaultValue="" placeholder="Phone" type="text" onBlur={(e)=>{
                  setPhone(e.target.value)
                }}/>
              </FormGroup>
              <FormGroup>
                <label>Name</label>
                <Input defaultValue="" placeholder="Full name" type="text" onBlur={(e)=>{
                  setName(e.target.value)
                }}/>
              </FormGroup>
              <FormGroup>
                <label>Service Interested </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <b>{selectedService}</b>
              <UncontrolledDropdown  style={{display: 'inline'}}>
                <DropdownToggle
                  className="btn-just-icon"
                  color="link"
                  size="lg"
                  caret
                >
                </DropdownToggle>
                <DropdownMenu >
                  {props.serviceList && props.serviceList.map((item)=> {
                    return(
                      <DropdownItem key={item.name}>
                        <a
                          key={item.name}
                          color="black"
                          href=""
                          value={item}
                          onClick={e => {
                            e.preventDefault();
                            setSelectedService(item.name)}}
                        >
                      <Row>
                        <Col sm="9">{item.name}</Col>
                      </Row>
                    </a>
                  </DropdownItem>
                    )
                  })}
                  
                  <DropdownItem divider />
                </DropdownMenu>
                </UncontrolledDropdown>
                </FormGroup>
                <div style={{textAlign: "center"}}>
                <small>Our pick ups are usually within 30 mins</small>
                </div>
              <Button block className="btn-round" color="danger" onClick={() => finalizeSchedule()}>
                Place Order For Pickup
              </Button>
            </div>
              </>
            )}
        </Modal>
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title">{props.name}</h1>
              <h3 className="description">
                15% discount across all laundry services
                <br />
                <small>Hurry, offer ends May 30</small>
              </h3>
              <br />
              <Button
                className="btn-round mr-1"
                color="neutral"
                onClick={() => window.open(`tel:${props.phone[0]?.phone}`)}
              >
                <i className="fa fa-phone" />
                Call
              </Button>
              <Button
                className="btn-round"
                color="neutral"
                type="button"
                outline
                onClick={() => setClassic(true)}
              >
                {success ? "Live Track Pickup" : "Order Instant Pickup"}
              </Button>
              <br />
              <br/>
              <Button
                className="btn-round"
                id="tooltip304204142"
                color="purple-color"
                type="button"
                onClick={() => {
                  setPricingModal(true)
                }}
                style={{
                  backgroundColor: "#9F2B68"
                }}
              >
                Pricing            
              </Button>
              <br />
              <br/>
              <small>
                {previousOrders && previousOrders.length > 0 && previousOrdersList()}
              </small>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
